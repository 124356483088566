import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import addToMailchimp from "gatsby-plugin-mailchimp"

import Container from "../commons/container"
import RoundButton from "../commons/round-button"

import ellipse from "../../assets/img/ellipse-zigzag.svg"

const Newsletter = ({ acf }) => {
  const [email, setEmail] = useState("")
  const [status, setStatus] = useState("")

  const _handleChange = (e) => {
    setEmail(e.target.value)
  }

  const _handleSubmit = (e) => {
    e.preventDefault()

    addToMailchimp(email)
      .then(({ msg, result }) => {
        if (result !== "success") {
          throw msg
        }
        setStatus(msg)
      })
      .catch((err) => {
        setStatus(err)
      })
  }

  //Get options page data
  const data = useStaticQuery(graphql`
    query {
      allWp {
        nodes {
          options {
            acfFooter {
              lead
              title
            }
          }
        }
      }
    }
  `)
  const { lead, title } = data.allWp.nodes[0].options.acfFooter

  return (
    <Section>
      <Container>
        <Title>{title}</Title>
        <Lead>{lead}</Lead>
        <form onSubmit={_handleSubmit}>
          <InputContainer>
            <EmailInput
              type="email"
              onChange={_handleChange}
              placeholder="Insira seu endereço de e-mail"
              name="email"
              value={email}
            />
          </InputContainer>
          <RoundButton type="submit" colors={{ color: "white", bkg: "#143d6e", hoverColor: "#143d6e" }}>
            Inscrever-se
          </RoundButton>
          <p
            dangerouslySetInnerHTML={{
              __html: status,
            }}
          />
        </form>
      </Container>
    </Section>
  )
}

export default Newsletter

const Section = styled.section`
  align-items: center;
  background-color: #143d6e;
  color: #e1e1d4;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 50px 15px;
  text-align: center;
`

const Title = styled.h2`
  font-size: 4.375rem;
  font-weight: normal;
  line-height: 1;
  margin: 0 0 1rem;

  @media (min-width: 991px) {
    font-size: 6.25rem;
    margin-top: 0.5em;
  }
`

const Lead = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
  margin: 0 auto 1rem;
  max-width: 500px;
  text-transform: lowercase;
`

const InputContainer = styled.div`
  align-items: center;
  background: center / contain no-repeat url(${ellipse});
  display: flex;
  height: 140px;
  justify-content: center;
  margin: 2rem auto;
  transition: filter 0.4s ease;
  width: 330px;

  &:focus-within {
    filter: drop-shadow(0 3px 0 #ffffff);
  }
`

const EmailInput = styled.input`
  appearance: none;
  background: transparent;
  border: 0;
  color: #143d6e;
  display: block;
  font-size: 1rem;
  text-align: center;
  outline: none !important;
  position: relative;
  transition: all 0.4s ease;
  text-transform: lowercase;
  width: 15em;
`
