import React from "react"
/** @jsx jsx */
import { css,  jsx } from '@emotion/react'
import styled from "@emotion/styled"
import { Link } from "gatsby"
import ResponsiveImage from "./responsive-image"

import DifficultyLabel from "./difficulty-label"
import TimeLabel from "./time-label"


function RecipeCard({ post, isHome, isSearch }) {
  /*
  * Creates cards for recipes thumbnails
  */
 const { acfRecipe, featuredImage, slug, title } = post
 const { cookingtime: ct, lead, difficulty } = acfRecipe

 const isSearchStyle = css`
  header * {
    color: rgba(255,255,212, .4);
  }
  :hover header h3 {
    color: #E1E1D4;
  }
 `
  return (
    <Link to={`/${slug}`} css={isSearch && isSearchStyle}>
      <RecipeContainer isHome={isHome}>
        <ImgContainer>
          <Figure isHome={isHome}>
            <ResponsiveImage
              image={featuredImage.node}
              style={css`
                display: block;
                height: 100%;
                object-fit: cover;
                width: 100%;
              `}
            />
          </Figure>
          <DifficultyLabel
            difficulty={difficulty}
            style={css`
              ${LabelsCommonStyle}
              left: 1em;
              text-transform: lowercase;
            `}
          />
          <TimeLabel
            t={ct}
            style={css`
              ${LabelsCommonStyle}
              text-transform: lowercase;
              right: 1em;
            `}
          />
        </ImgContainer>
        <Header isHome={isHome}>
          <Title isHome={isHome}>{title}</Title>
          <Tag isHome={isHome}>{lead}</Tag>
        </Header>
      </RecipeContainer>
    </Link>
  )
}

export default RecipeCard

const RecipeContainer = styled.article`
  ${({isHome}) => isHome ? 'background-color: white;' : ''} 
  ${({isHome}) => isHome ? 'border-radius: 10px;' : ''} 
  ${({isHome}) => isHome ? 'height: 100%;' : ''} 
  ${({isHome}) => isHome ? 'overflow: hidden;' : ''}
`

const ImgContainer = styled.div`
  height: 0;
  margin: 0;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  &::before {
    content: '';

    background: linear-gradient(to bottom, rgba(0,0,0,.4) 0%,transparent 45%);
    border-radius: 10px;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
`

const Figure = styled.figure`
  
  ${({isHome}) => isHome ? '' : 'border-radius: 10px;'} 

  height: 100%;
  margin: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 1;
`

const LabelsCommonStyle = `
  display: none;

  @media (min-width: 768px) {
    color: #ffffff;
    display: block;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    position: absolute;
    top: 1em;
    z-index: 9;
  }
`

const Header = styled.header`
 
  ${({isHome}) => isHome ? ' padding: 0 1em 1.5em;' : ''} 
  @media (min-width: 991px) {
    max-width: 80%;
  }
`

const Title = styled.h3`
  ${({isHome}) => isHome ? '' : 'font-family: "halyard-display", serif;'} 
  ${({isHome}) => isHome ? 'text-transform: uppercase;' : 'text-transform: none;'} 
  ${({isHome}) => isHome ? 'font-size: 3.125rem;' : 'font-size: 1.125rem;'} 
  ${({isHome}) => isHome ? 'line-height: 3.125rem;' : 'line-height: 1.2;'} 
  font-weight: 500;
  margin: 0.5em 0 0;
`

const Tag = styled.p`
  ${({isHome}) => isHome ? 'font-size: 1.125rem;' : 'font-size: 0.875rem;'} 
  ${({isHome}) => isHome ? 'color: #000000;' : 'color: #4c4c4c;'} 
  font-weight: 500;
  margin: 0.25em 0 0;
  text-transform: lowercase;
`
