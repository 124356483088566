import React from 'react'
import SearchIcon from "./searchIcon"
import { components } from "react-select"

const { ValueContainer, Placeholder } = components
const getPlaceholderText = (props) => 
  props.hasValue ? props.selectProps.placeholder : "O que gostaria de cozinhar?" 

export const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
      <Placeholder {...props}>
        {props.selectProps.inputValue === "" && getPlaceholderText(props)}
      </Placeholder>
    </ValueContainer>
  );
};

export const IndicatorsContainer = () => ( <SearchIcon /> )
