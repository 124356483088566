import React, { useEffect, useState } from "react"
import Select from "react-select"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { gql, useQuery } from "@apollo/client"
import { css, jsx } from "@emotion/react"
import SearchResults from "./searchResults"
import Container from "../../commons/container"
import { CustomValueContainer, IndicatorsContainer } from "./selectCustomComponents"
import { customStyles } from "./selectCustomStyles"
import { context } from "../../context/provider"
import { use100vh } from 'react-div-100vh'

const SEARCH_TAGS = gql`
  query getSearchResults($tags: [String]) {
    posts(
      where: { tagSlugAnd: $tags, orderby: { field: DATE, order: DESC } }
    ) {
      edges {
        node {
          title
          slug
          featuredImage {
            node {
              altText
              srcSet
            }
          }
          acfRecipe {
            difficulty
            lead
            cookingtime {
              hours
              minutes
            }
            maintag {
              name
            }
          }
        }
      }
    }
  }
`

const SearchForm = (props) => {
  const tags = useStaticQuery(graphql`
    query allTags {
      allWpTag(sort: {fields: count, order: DESC}) {
        edges {
          node {
            count
            slug
            name
            databaseId
          }
        }
      }
    }
  `)

  const filteredTags = tags.allWpTag.edges.filter(tag => tag.node.count)
  const options = filteredTags.map(tag => {
    return {
      value: tag.node.slug,
      label: tag.node.name,
    }
  })

  const [tagsFilter, setTagsFilter] = useState("")
  const [value, setValue] = useState([])
  const [menuIsOpen, setMenuState] = useState(false)
  const [inputValue, setInputValue] = useState("")

  const handleSelectedTags = (selectedOptions) => {
    const selectedTagsIds = selectedOptions.map(({ value }) => value)
    setValue(selectedOptions)
    setTagsFilter(selectedTagsIds)
  } 

  const handleInputChange = event => setInputValue(event)
  
  const { loading, error, data } = useQuery(SEARCH_TAGS, {
    variables: { tags: tagsFilter },
    fetchPolicy: "no-cache"
  })

 
  useEffect(() => {
    if(inputValue.length >= 3) {
      setMenuState(true);
    } else {
      setMenuState(false)
    }
  }, [inputValue])


  const height = use100vh()
  const fullHeight = height ? height + 'px' : '100vh'
  return (
    <context.Consumer>
      {(context) => {
        return (
          <SearchContainer isOpen={props.isOpen} css={css`height: ${fullHeight};`}>
            <Container
              css={css`
                padding: 48px 25px;

                @media (min-width: 1250px) {
                  margin: 0 auto;
                  padding: 48px 0 40px;
                }
              `}
            >
              <SearchFormContainer>
                <Select
                  instanceId="searchInput"
                  styles={customStyles}
                  placeholder={"Adicionar"}
                  isMulti
                  value={value}
                  options={options}
                  onInputChange={handleInputChange}
                  menuIsOpen={menuIsOpen}
                  onChange={handleSelectedTags}
                  components={{
                    ValueContainer: CustomValueContainer,
                    IndicatorsContainer
                  }}
                />
              
              {/* {extrenalOptions.map((item, i) =>
                <span key={i} data-value={item.value}>{item.label} </span>
              )} */}

              </SearchFormContainer>
              {tagsFilter.length > 0 && <SearchResults loading={loading} error={error} data={data} />}
            </Container>
          </SearchContainer>
        )
      }}
    </context.Consumer>
  )
}

const SearchFormContainer = styled.div`
  position: relative;
  z-index: 9999;
`

const SearchContainer = styled.div`
  background-color: #143d6e;
  left: 0;
  overflow: scroll;
  padding-top: 45px;
  pointer-events: auto;
  position: fixed;
  top: 0;
  transition: .4s ease;
  transform: ${(props) => props.isOpen ? 'translateY(0)' : 'translateY(-100%)'};
  width: 100%;
  z-index: -1;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
    
`

export default SearchForm