import React from "react"

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g id="plus" transform="translate(1 1)">
        <line
          id="Line_101"
          data-name="Line 101"
          y2="14"
          transform="translate(7)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_102"
          data-name="Line 102"
          x2="14"
          transform="translate(0 7)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}

export default Icon
