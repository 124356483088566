import styled from "@emotion/styled"

export const CardsContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  grid-gap: 2rem;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`