import React from "react"
import Card from "../../commons/recipe-card"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/react"
import { CardsContainer } from "../../commons/cards-container"

const SearchResults = ({ loading, error, data }) => {
  if (loading)
    return (
      <SearchResultsContainer>
        <SearchResultsCount
          css={css`
            font-size: 1.125rem;
          `}
        >
          carregando receitas...
        </SearchResultsCount>
      </SearchResultsContainer>
    )
  if (error) return console.error(`Erro ao carregar receitas: `, error)

  const recipes = data?.posts?.edges

  const recipesCountText =
    recipes.length && recipes.length === 1 ? "receita" : "receitas"
  const resultsCountText = !recipes.length
    ? "nenhuma receita encontrada"
    : `encontramos ${recipes.length} ${recipesCountText}`

  return (
    <SearchResultsContainer>
      <SearchResultsCount>{resultsCountText}</SearchResultsCount>
      <CardsContainer css={css`row-gap: 3rem;`}>
        {recipes.map((post, i) => (
          <Card key={i} post={post.node} isSearch />
        ))}
      </CardsContainer>
    </SearchResultsContainer>
  )
}

const SearchResultsContainer = styled.section`
  padding-top: 50px;
  z-index: 9999;
`

const SearchResultsCount = styled.span`
  color: #e1e1d4;
  display: block;
  font-size: 1.875rem;
  margin-bottom: 40px;
`

export default SearchResults
