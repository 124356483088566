import React from 'react'

const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.414" height="20.414" viewBox="0 0 20.414 20.414">
      <g id="search" transform="translate(-2 -2)">
        <circle cx="8" cy="8" r="8" transform="translate(3 3)" fill="none" stroke="#e1e1d4" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        <line x1="4.35" y1="4.35" transform="translate(16.65 16.65)" fill="none" stroke="#e1e1d4" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      </g>
    </svg>
  )
}

export default SearchIcon