import React, { useState } from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import MainMenu from "./mainMenu"
import Icon from "./menuIcon"
import SearchIcon from "./search/searchIcon"
import SearchForm from "./search/searchForm"

const Header = () => {

  const [menuOpen, setMenu] = useState(false)
  const toggleMenu = () => setMenu(!menuOpen)

  const [searchOpen, setSearch] = useState(false)
  const toggleSearch = () => setSearch(!searchOpen)

  const handleMenuIconClick = () => {
    toggleMenu()
    if (searchOpen) {
      toggleSearch()
    }
  }
  
  const handleSearchIconClick = () => {
    toggleSearch()
    if (menuOpen) {
      toggleMenu()
    }
  }
  
  const handleHomeLinkClick = () => {
    if (searchOpen) {
      toggleSearch()
    }

    if (menuOpen) {
      toggleMenu()
    }
  }

  return (
    <>
      <HeaderContainer>
        <MenuIcon onClick={handleMenuIconClick} isOpen={menuOpen}>
          <Icon />
        </MenuIcon>

        {/* TO-DO: Dynamic title from wordpress */}
        <Title className="title" alt="Gui Poulain">
          <StyledLink to="/" onClick={handleHomeLinkClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1679.43 248.18"
            >
              <polygon points="1458.48 36.55 1458.48 245.53 1433.6 245.53 1433.6 4.17 1458.48 4.17 1655.23 209.37 1654.2 4.15 1679.43 4.15 1679.43 245.53 1655.23 245.53 1458.48 36.55" />
              <path d="M847.58,36.82C826.27,12.73,796.36,0,761.08,0c-34.61,0-64.34,12.73-86,36.82-20.3,22.61-31.47,53.49-31.47,87,0,33.73,11.17,64.81,31.47,87.49,21.6,24.14,51.33,36.9,86,36.9,35.31,0,65.22-12.76,86.5-36.9,19.76-22.42,30.64-53.49,30.64-87.49,0-33.74-10.88-64.62-30.64-87m7.44,87c0,29.55-8.7,54.85-25.18,73.16-16.85,18.73-40.63,28.63-68.76,28.63-27.47,0-51.07-9.9-68.24-28.63-16.77-18.31-26-44.29-26-73.16,0-59.76,38.76-101.5,94.25-101.5,28.08,0,51.86,9.93,68.76,28.71C846.32,69.31,855,94.48,855,123.79" />
              <path d="M1238.65,4.15h-26.49l-109,218.36h-57V4.16h-24.76V245.6h98.38l34-69.57h145.68l35,69.57h27.85Zm-74,150.06,60.79-124.14,62.82,124.14Z" />
              <path d="M995.55,181.82c0,48.24-15.58,66.22-49.74,66.22-33.86,0-49.44-18-49.44-66.22l.2-177.66H919l-.19,179.16c0,31.76,6,42.25,27,42.25,21.28,0,27.27-10.49,27.27-42.25l.2-179.16h22.47Z" />
              <path d="M355.71,181.82c0,48.24-15.58,66.22-49.74,66.22-33.86,0-49.44-18-49.44-66.22l.2-177.66H279.2L279,183.32c0,31.76,6,42.25,27,42.25,21.28,0,27.27-10.49,27.27-42.25l.2-179.16h22.47Z" />
              <rect x="1378.31" y="48" width="24.83" height="197.55" />
              <path d="M1414.32,12q0,10.32-23.59,10.31T1367.13,12q0-10.3,23.6-10.31T1414.32,12" />
              <rect x="385.29" y="48" width="24.83" height="197.55" />
              <path d="M421.3,12q0,10.32-23.59,10.31T374.11,12q0-10.3,23.6-10.31T421.3,12" />
              <path d="M562.49,4.15H524.27V245.51H548V127.07h14.45c48.88,0,74.77-19.53,74.77-61.46,0-41.35-26.56-61.46-74.77-61.46m-1,102.62H548V24.45h13.43c35.48,0,54.34,14.5,54.34,41.16,0,26.31-19.34,41.16-54.34,41.16" />
              <path d="M78,121.17v21.5H210.1c-3,21.47-11,40-23.89,54.28-16.86,18.73-40.64,28.63-68.77,28.63-27.47,0-51.06-9.9-68.23-28.63-16.78-18.31-26-44.29-26-73.16C23.2,64,62,22.29,117.44,22.29c44.92,0,78.52,25.79,89.89,69l.06.2h23.68l-.08-.33c-6-26.49-19.09-49-37.93-65C172.94,9.05,146.79,0,117.44,0c-34.61,0-64.34,12.73-86,36.82C11.18,59.43,0,90.31,0,123.79c0,33.74,11.18,64.81,31.47,87.49,21.6,24.14,51.33,36.9,86,36.9,35.31,0,65.22-12.76,86.5-36.9a110.38,110.38,0,0,0,9-11.7l2.9,46,17.33,0,.14-124.42Z" />
            </svg>
          </StyledLink>
        </Title>
        <SearchIconContainer onClick={handleSearchIconClick} isOpen={searchOpen}>
          {searchOpen ? <Icon /> : <SearchIcon />}
        </SearchIconContainer>
      </HeaderContainer>
      <MainMenu isOpen={menuOpen} />
      <SearchForm isOpen={searchOpen} />
    </>
  )

}

export default Header

const HeaderContainer = styled.div`
  color: #fff;
  display: flex;
  height: 55px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  justify-content: space-between;
`

const MenuIcon = styled.div`
  cursor: pointer;
  padding: 7px;
  pointer-events: auto;
  transform: ${props => props.isOpen ? 'rotate(45deg)' : 'rotate(0)'};
  transition: .4s ease;

  @media (min-width: 800px) {
    padding: 10px;
  }

  * {
    pointer-events: none;
  }
`

const Title = styled.h2`
  align-items: center;
  display: flex;
  flex: 0 0 150px;
  margin: 0 auto;

  svg {
    fill: #ffffff;
    height: 21px;
    width: auto;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  pointer-events: all;
  text-decoration: none;
`

const SearchIconContainer = styled.div`
  width: 40px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  padding: 10px;
  pointer-events: auto;
  transform: ${props => props.isOpen ? 'rotate(45deg)' : 'rotate(0)'};
`


