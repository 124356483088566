import React from "react"
import { context } from "../context/provider"
/** @jsx jsx */
import { css,  jsx } from '@emotion/react'

const DifficultyLabel = ({ difficulty, style }) => (
  <context.Consumer>
    {(context) => {
      const { difficultyTiers } = context

      return (
        <p css={css(style)}>
          {
            difficultyTiers.find((item) => item.value === parseInt(difficulty))
              ?.label
          }
        </p>
      )
    }}
  </context.Consumer>
)

export default DifficultyLabel
