import React from "react"
/** @jsx jsx */
import { css,  jsx } from '@emotion/react'

const TimeLabel = ({ t, style }) => (
  <time
    css={css(style)}
    dateTime={`PT${t.hours ? t.hours + "H" : ""}${
      t.minutes ? t.minutes + "M" : ""
    }`}
  >
    {t.hours ? t.hours + " H " : ""}
    {t.minutes ? t.minutes + " MIN" : ""}
  </time>
)

export default TimeLabel