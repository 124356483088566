import React from "react"
import styled from "@emotion/styled"

const MainContsainer = (props) => <Container {...props}>{props.children}</Container>
export default MainContsainer

const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  width: 100%;
`
