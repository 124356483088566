import React from "react"
import styled from "@emotion/styled"
/** @jsx jsx */
import { css, Global, jsx } from "@emotion/react"

import Footer from "./footer"
import Header from "./header"

import * as normalize from "../styles/normalize.css"
import * as globalStyles from "../styles/global.css"
import SEO from "./commons/seo"

const Layout = ({ children, seo }) => (
  <>
    {/* Add some global styles for the whole site*/}
    <Global styles={css(normalize)} />
    <Global styles={css(globalStyles)} />

    {/* Add SEO */}
    {seo && <SEO seo={seo} />}

    {/* Header */}
    <HeaderContainer>
      <Header />
    </HeaderContainer>

    {/* Content */}
    <Main>{children}</Main>

    {/* Footer */}
    <Footer />
  </>
)

export default Layout

const HeaderContainer = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`

const Main = styled.main`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding-top: 45px;
`
