import React from "react"
import styled from "@emotion/styled"

import Newsletter from "./newsletter"
import Colophon from "./colophon"

const MainFooter = (params) => {
  return (
    <Footer>
      <Newsletter />
      <Colophon />
    </Footer>
  )
}

export default MainFooter

const Footer = styled.footer`
  margin-top: 50px;
`
