import React from "react"
import Img from "gatsby-image"
/** @jsx jsx */
import { css,  jsx } from '@emotion/react'
import styled from "@emotion/styled"

const ResponsiveImage = ({ image, style }) => {
  // Create responsive image with traditional img or gatsby-image

  if (!!image?.localFile?.childImageSharp?.fluid) {
    return (
      <StyledGatsbyImage
        alt={image.altText}
        fluid={image.localFile.childImageSharp.fluid}
        css={css(style)}
      />
    )
  } else if (!!image?.srcSet) {
    return (
      <StyledImage alt={image.altText} srcSet={image.srcSet} css={css(style)} />
    )
  } else {
    return null
  }
}

export default ResponsiveImage

const commonStyle = `
  display: block;
  height: auto;
  max-width: 100%;
`

const StyledGatsbyImage = styled(Img)({ commonStyle })
const StyledImage = styled.img({ commonStyle })
