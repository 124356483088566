import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { context } from "../../components/context/provider"

const MenuItem = ({ data }) => {
  const { url, label, target } = data

  return (
    <context.Consumer>
      {(context) => {
        const { toggleMenu } = context
        return (
          <Item>
            {target === "_blank" ? (
              <a href={url} target={target} rel="noreferrer">
                {label}
              </a>
            ) : (
              <Link to={url} onClick={toggleMenu}>{label}</Link>
            )}
          </Item>
        )
      }}
    </context.Consumer>
  )
}

export default MenuItem

const Item = styled.li`
  color: #e1e1d4;
  font-family: "Altissima Condensed", serif;
  font-size: 13vh;
  line-height: 13vh;
  text-transform: uppercase;

  @media (min-width: 800px) {
    font-size: 6.2vw;
  }

  * {
    color: currentColor;
  }
`
