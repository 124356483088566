import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import MenuItem from "./menuItem"
import { context } from "../../components/context/provider"
import { use100vh } from 'react-div-100vh'
import { css } from "@emotion/react"

const MainMenu = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allWpMenuItem(
        filter: { menu: { node: { slug: { eq: "links-institucionais" } } } }
      ) {
        nodes {
          url
          label
          menu {
            node {
              slug
            }
          }
          order
          target
        }
      }
    }
  `)

  const menuItems = data.allWpMenuItem.nodes
  menuItems.sort((a, b) => a.order - b.order)

  const height = use100vh()
  const fullHeight = height ? height + 'px' : '100vh'
  
  
        
  return (
    <MenuContainer isOpen={props.isOpen} css={css`height: ${fullHeight};`}>
      <ItemsConttainer isOpen={props.isOpen}>
        {menuItems.map((data, i) => (
          <MenuItem key={i} data={data} />
        ))}
      </ItemsConttainer>
    </MenuContainer>
  )
}

export default MainMenu

const MenuContainer = styled.div`
  background-color: #d4291a;
  padding: 70px 20px 30px;
  pointer-events: auto;
  position: relative;
  top: -55px;
  transform: ${(props) => props.isOpen ? 'translateY(0)' : 'translateY(-100%)'};
  transition: .4s ease;
  width: 100%;
  z-index: -1;

  @media (min-width: 800px) {    
    height: auto;
    padding: 95px 50px 30px;
  }
`

const ItemsConttainer = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  list-style: none;
  margin: 0;
  opacity: ${(props) => props.isOpen ? '1' : '0'};
  padding: 0;
  pointer-events: auto;
  transform: ${(props) => props.isOpen ? 'translateY(0)' : 'translateY(-80%)'};
  transition: 1s ease;
  
  @media (min-width: 800px) {
    flex-direction: row;
    height: auto;
    justify-content: space-between;
  }
`
