import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import MenuItem from "./menuItem"

const Colophon = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpMenuItem {
        nodes {
          url
          label
          menu {
            node {
              slug
            }
          }
          order
          target
        }
      }

      allWp {
        nodes {
          options {
            acfFooter {
              colophontitle
              signature
            }
          }
        }
      }
    }
  `)

  const { colophontitle, signature } = data.allWp.nodes[0].options.acfFooter

  const menuItems = data.allWpMenuItem.nodes
  const institutionalMenu = menuItems.filter(
    (el) => el.menu.node.slug === "links-institucionais"
  )
  const socialMenu = menuItems.filter(
    (el) => el.menu.node.slug === "links-sociais"
  )

  return (
    <ColophonContainer>
      <ColophonTitle
        dangerouslySetInnerHTML={{
          __html: colophontitle,
        }}
      />

      <ColophonSignature
        dangerouslySetInnerHTML={{
          __html: signature,
        }}
      />

      <FooterMenusContainer>
        <FooterMenu>
          {institutionalMenu.map((data, i) => (
            <MenuItem key={i} data={data} />
          ))}
        </FooterMenu>
        <FooterMenu>
          {socialMenu.map((data, i) => (
            <MenuItem key={i} data={data} />
          ))}
        </FooterMenu>
      </FooterMenusContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.304"
        height="34.42"
        viewBox="0 0 34.304 34.42"
      >
        <path
          d="M144.98,908.9a5.187,5.187,0,0,1,0-.839,9.477,9.477,0,0,0-.186-1.98,11.73,11.73,0,0,0-.686-1.832c-.21-.533-.545-.963-.789-1.484-.273-.583-.676-1.023-.988-1.578a15.757,15.757,0,0,0-.826-1.506c-.353-.494-.874-.892-1.2-1.39a4.378,4.378,0,0,0-1.109-1.424,14.81,14.81,0,0,1-1.438-.919,4.992,4.992,0,0,0-.7-.566c-.3-.166-.642-.178-.94-.333A14.71,14.71,0,0,0,134.386,894c-.486-.175-.986-.159-1.455-.379a6,6,0,0,0-1.912-.644,8.6,8.6,0,0,0-1.128-.036c-.236-.082-.473-.162-.721-.222a8.444,8.444,0,0,0-1.842-.148,10.993,10.993,0,0,0-3.686.493c-1.057.337-2.16.711-3.187,1.139a9.358,9.358,0,0,0-1.728,1.035,12.343,12.343,0,0,0-1.439,1.036,15.069,15.069,0,0,1-1.255,1.2,6.86,6.86,0,0,0-1.361,1.3,7.323,7.323,0,0,0-.936,1.953,6.477,6.477,0,0,0-1.013,1.561,6.033,6.033,0,0,0-.254.74,4.025,4.025,0,0,0-.909,2.336c-.053.124-.1.25-.147.379a14.8,14.8,0,0,0-.023,7.729,13.813,13.813,0,0,0,.978,3.249c.272.555.7,1,1,1.545a16.457,16.457,0,0,0,.96,1.708c.632.9,1.1,2.177,2.061,2.792a4.156,4.156,0,0,0,.678.331l.034.029c.316.238.681.408.993.648a9.609,9.609,0,0,0,1,.8c.39.228.806.409,1.2.626a4.577,4.577,0,0,0,1.537.423,10.657,10.657,0,0,0,1.335.472,9.807,9.807,0,0,0,2.122.454,2.526,2.526,0,0,0,.6.242,5.141,5.141,0,0,0,2.374.093c.938-.27,1.876-.553,2.816-.914a22.654,22.654,0,0,0,3.009-.92,21.458,21.458,0,0,0,2.816-1.289c.854-.639,1.655-1.36,2.523-1.98a19.053,19.053,0,0,0,2.154-2.16,9.344,9.344,0,0,0,1.429-2.689c.552-1.051.942-2.159,1.53-3.2a5.473,5.473,0,0,0,.542-2.662,5.306,5.306,0,0,0,.108-1.218A7.091,7.091,0,0,0,144.98,908.9Z"
          transform="translate(-110.884 -892.565)"
          fill="#d6291a"
        />
      </svg>
    </ColophonContainer>
  )
}

export default Colophon


const ColophonContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px;
  background-color: #e1e1d4;

  @media (min-width: 800px) {
    padding: 60px 30px;
  }
`

const ColophonTitle = styled.div`
  border-bottom: 2px dotted #143d6e;
  color: #143d6e;
  font-family: "Altissima Condensed", serif;
  font-size: 10vh;
  line-height: 1;
  padding-bottom: 40px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;

  @media (min-width: 800px) {
    font-size: 6.25rem;
    max-width: 1176px;
    margin: auto;
  }

  * {
    margin: 0;
  }
`

const ColophonSignature = styled.div`
  color: #d4291a;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.875rem;
  padding-top: 70px;
  text-align: center;

  * {
    margin: 0;
  }
`

const FooterMenusContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 0;

  @media (min-width: 800px) {
    flex-direction: column;
    padding: 40px 0;
  }
`

const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  max-width: 50%;
  min-width: 160px;
  padding: 0;
  text-align: center;

  @media (min-width: 800px) {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 8px;
    max-width: 100%;
  }
`