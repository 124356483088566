import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ seo }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const { siteUrl } = data.site.siteMetadata
  const fullUrl = siteUrl + seo.opengraphUrl

  return (
    <Helmet>
      <html lang="pt-br" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="og:type" content="website" />

      {seo.opengraphUrl && <link rel="canonical" href={fullUrl} />}
      {seo.opengraphUrl && <meta property="og:url" content={fullUrl} />}

      {seo.title && (
        <title itemProp="name" lang="pt-br">
          {" "}
          {seo.title}{" "}
        </title>
      )}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.opengraphDescription && (
        <meta name="description" content={seo.opengraphDescription} />
      )}
      {seo.opengraphDescription && (
        <meta property="og:description" content={seo.opengraphDescription} />
      )}

      {seo.opengraphImage?.sourceUrl && (
        <meta property="og:image" content={seo.opengraphImage.sourceUrl} />
      )}
      {seo.opengraphImage?.sourceUrl && (
        <meta name="image" content={seo.opengraphImage.sourceUrl} />
      )}
    </Helmet>
  )
}

export default SEO
