export const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    borderColor: "rgba(255,255,212, .4)",
    boxShadow: 'none',
    color: "rgba(255,255,212, .4)",
    padding: "7px 15px 10px 10px",
    ':hover': {
      borderColor: "rgba(255,255,255,.6)"
    }
  }),

  input: (styles) => ({
    ...styles,
    color: "#E1E1D4"
  }),

  placeholder: (styles, state) => ({
    ...styles,
    fontSize: "1.125rem",
    color: state.hasValue ? "rgba(255,255,212, .4)" : "#E1E1D4",
    position: "static",
    transform: "translate(-6px,0)",
    textTransform: "lowercase"
  }),

  // menu: () => ({
  //   paddingTop: "30px"
  // }),
  
  // menuList: () => ({
  //   display: "flex",
  //   flexWrap: "wrap"
  // }),

  // option: () => ({
  //   backgroundColor: "#195091",
  //   border: "1px solid #083265",
  //   borderRadius: "30px",
  //   cursor: "pointer",
  //   color: "#E1E1D4",
  //   fontSize: "18px",
  //   fontWeight: "500",
  //   marginRight: "10px",
  //   marginBottom: "10px",
  //   textTransform: "lowercase",
  //   padding: "5px 15px 10px",
  //   ':hover': {
  //     backgroundColor: "#083265",
  //   },
  //   ':nth-of-type(3) ~ *': {
  //     display: "none"
  //   }
  // }),

  multiValue: () => ({
    alignItems: "center",
    display: "flex",
    paddingRight: "15px"
  }),

  multiValueLabel: () => ({
    backgroundColor: "transparent",
    color: "#D99F4F",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "lowercase"
  }),
  
  multiValueRemove: () => ({
    backgroundColor: "transparent",
    color: "#D99F4F",
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: "500",
    padding: "5px 0 0 0",
    ':hover': {
      opacity: ".7"
    }
  }),

  indicatorsContainer: () => ({ display: "none" })
}