import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const MenuItem = ({ data }) => {
  const { url, label, target } = data

  return (
    <Item>
      {target === "_blank" ? (
        <a href={url} target={target} rel="noreferrer">
          {label}
        </a>
      ) : (
        <Link to={url}>{label}</Link>
      )}
    </Item>
  )
}

export default MenuItem

const Item = styled.li`
  color: #D4291A;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  margin-bottom: 20px;
  padding: 0 10px;

  @media (min-width: 800px) {
    margin: 0;
  }
  
  * {
    color: currentColor;
  }
`
