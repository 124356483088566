import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { roundButtonStyles } from "./round-button-styles"

function RoundButton({ colors, children, type }) {
  const colorsDefaults = {
    bkg: "transparent",
    color: "#ffffff",
    hoverColor: "#ffffff"
  }
  const colorsWithDefaults = { ...colorsDefaults, ...colors }

  return (
    <Button colors={colorsWithDefaults} type={type}>
      {children}
    </Button>
  )
}

RoundButton.propTypes = {
  colors: PropTypes.shape({
    bkg: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
  type: PropTypes.string,
}

export default RoundButton

const Button = styled.button`
  ${roundButtonStyles}
`