import { css } from '@emotion/react'

export const roundButtonStyles = props =>
  css`
    background: ${props.colors.bkg};
    border: 2px solid ${props.colors.color};
    border-radius: 999px;
    color: ${props.colors.color};
    display: block;
    font-size: 1.125rem;
    margin: 20px auto;
    outline: none;
    padding: 0.5em 1.667em 0.7em 1.667em;
    text-transform: lowercase;
    cursor: pointer;

    &:focus {
      filter: brightness(1.15);
    }

    &:hover {
      color: ${props.colors.hoverColor};
      background: ${props.colors.color};
      filter: none;
    }
  `